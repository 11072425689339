export function getCatId(cat){
    if(cat == 'book') return 1;
    else if(cat == 'anime') return 2;
    else if(cat == 'music') return 3;
    else if(cat == 'game') return 4;
    else if (cat == 'real') return 6;
  }


export function getCatName(cat){
    if(cat == 'book') return '书籍';
    else if(cat == 'anime') return '动画';
    else if(cat == 'music') return '音乐';
    else if(cat == 'game') return '游戏';
    else if (cat == 'real') return '三次元';
  }
