import React from "react";

function Footer(){
    return(<div id="footer">
    <ul id="footerLinks" className="clearit">
      <li className="first">
        <dl>
          <dt>关于我们</dt>
          <dd><a href="https://bangumi.tv/about">关于我们</a></dd>
          <dd><a href="https://bangumi.tv/about/guideline">社区指导原则</a></dd><dd>
          </dd><dd><a href="https://bangumi.tv/about/copyright">版权声明</a></dd><dd>
          </dd><dd><a href="https://bangumi.tv/about/link2us">链接我们</a></dd><dd>
          </dd></dl>
      </li>
      <li>
        <dl>
          <dt>获取帮助</dt>
          <dd><a href="https://bangumi.tv/help/bbcode">BBCode</a></dd>
          <dd><a href="https://bangumi.tv/group/forum">站务论坛</a></dd>
          <dd><a href="https://bangumi.tv/group/dev">番组开发</a></dd>
          <dd><a href="https://bangumi.tv/group/issues">BUG追踪</a></dd>
          <dd><a href="https://bangumi.tv/group/doujin">天窗站务</a></dd>
        </dl>
      </li>
      <li>
        <dl>
          <dt>特别推荐</dt>
          <dd><a href="https://bangumi.tv/award/2021">年鉴 2021</a> | <a href="https://bangumi.tv/award/2020">20</a> | <a href="https://bangumi.tv/award/2019">19</a> | <a href="https://bangumi.tv/award/2018">18</a> | <a href="https://bangumi.tv/award/2017">17</a> |  <a href="https://bangumi.tv/award/2016">16</a> |  <a href="https://bangumi.tv/award/2015">15</a> | <a href="https://bangumi.tv/award/2014">14</a> |  <a href="https://bangumi.tv/award/2013">13</a> | <a href="https://bangumi.tv/award/2012">12</a> | <a href="https://bangumi.tv/award/2011">11</a> | <a href="https://bangumi.tv/award/2010">10</a></dd>
          <dd><a href="https://bangumi.tv/group/wiki">番組WIKI計画</a></dd>
          <dd><a href="https://bangumi.tv/onair">onAir 客户端</a></dd>
          <dd><a href="https://bangumi.tv/tokei">etokei 绘时计</a></dd>
        </dl>
      </li>
      <li className="last">
        <dl>
          <dt>更多</dt>
          <dd><a href="https://bangumi.tv/dev/app">开发者平台</a></dd>
          <dd><a href="https://bangumi.tv/index">目录</a></dd>
          <dd><a href="https://bangumi.tv/wiki">维基人</a></dd>
          <dd><a href="https://bangumi.tv/magi">MAGI 问答</a></dd>
          <dd><a href="https://bangumi.tv/goodies">周边</a></dd>
          <dd><a href="https://bangumi.tv/dollars">Dollars</a></dd>
        </dl>
      </li>
    </ul><div className="copyright"><small className="grey">© 2008-2022 Bangumi (a.k.a.Chobits), some rights reserved | r351</small></div>
  </div>);
}

export default Footer