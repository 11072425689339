import React from "react";

function Header(props){
    let cat = props.data
    return (
        <div id="headerNeue2">
        <div className="headerNeueInner clearit">
          <div className="bg musume_4" />
          <a href="/" className="logo">Bangumi 番组计划</a>
          <input type="checkbox" id="navMenuNeueToggle" />    	
          <label htmlFor="navMenuNeueToggle" className="menuCompact" />    
          <div id="navNeue2">
            <div id="menuNeue">
              <ul id="navMenuNeue" className="clearit">
                <li>
                  {<a href="//bangumi.tv/anime" className={(cat === "anime" ? "focus" : "top") + " chl anime"}><span>动画</span></a> }
                  <div className="clear" />
                  <ul className="clearit">
                    <li><a href="//bangumi.tv/anime/chart" className="nav">排行榜</a></li>
                    <li><a href="//bangumi.tv/calendar" className="nav">每日放送</a></li>
                    <li><a href="/anime/tag" className="nav">动画标签</a></li>
                    <li><a href="//bangumi.tv/anime/browser" className="nav">分类浏览</a></li>
                    <li><a href="//bangumi.tv/anime/blog" className="nav">动画日志</a></li>
                  </ul>
                </li>
                <li>
                  {<a href="//bangumi.tv/book" className={(cat === "book" ? "focus" : "top") + " chl"}><span>书籍</span></a>}
                  <div className="clear" />
                  <ul className="clearit">
                    <li><a href="//bangumi.tv/book/chart" className="nav">排行榜</a></li>
                    <li><a href="/book/tag" className="nav">图书标签</a></li>
                    <li><a href="//bangumi.tv/book/browser" className="nav">分类浏览</a></li>
                    <li><a href="//bangumi.tv/book/blog" className="nav">图书日志</a></li>
                  </ul>
                </li>
                <li>
                  {<a href="//bangumi.tv/music" className={(cat === "music" ? "focus" : "top") + " chl"}><span>音乐</span></a>}
                  <div className="clear" />
                  <ul className="clearit">
                    <li><a href="//bangumi.tv/music/chart" className="nav">排行榜</a></li>
                    <li><a href="/music/tag" className="nav">音乐标签</a></li>
                    <li><a href="//bangumi.tv/music/browser" className="nav">浏览全部</a></li>
                    <li><a href="//bangumi.tv/music/blog" className="nav">音乐日志</a></li>
                  </ul>
                </li>
                <li>
                  {<a href="//bangumi.tv/game" className={(cat === "game" ? "focus" : "top") +" chl"}><span>游戏</span></a>}
                  <div className="clear" />
                  <ul className="clearit">
                    <li><a href="//bangumi.tv/game/chart" className="nav">排行榜</a></li>
                    <li><a href="/game/tag" className="nav">游戏标签</a></li>
                    <li><a href="//bangumi.tv/game/browser" className="nav">平台浏览</a></li>
                    <li><a href="//bangumi.tv/game/blog" className="nav">游戏日志</a></li>
                  </ul>
                </li>
                <li>
                  {<a href="//bangumi.tv/real" className={(cat === "real" ? "focus" : "top") +" chl real"}><span>三次元</span></a>}
                  <div className="clear" />
                  <ul className="clearit">
                    <li><a href="//bangumi.tv/real/chart" className="nav">排行榜</a></li>
                    <li><a href="/real/tag" className="nav">三次元标签</a></li>
                    <li><a href="//bangumi.tv/real/blog" className="nav">三次元日志</a></li>
                    <li><a href="//bangumi.tv/real/browser/platform/jp" className="nav">日剧</a></li>
                    <li><a href="//bangumi.tv/real/browser/platform/en" className="nav">欧美剧</a></li>
                  </ul>
                </li>
                <li><a href="//bangumi.tv/mono" className="top"><span>人物</span></a>
                  <div className="clear" />
                  <ul className="clearit">
                    <li><a href="//bangumi.tv/character" className="nav">虚构人物</a></li>
                    <li><a href="//bangumi.tv/person" className="nav">现实人物</a></li>
                    {/* <li><div className="sep">我</div></li>
                    <li><a href="/mono/update" className="nav">关注人物更新</a></li>
                    <li><a href="/user/ychz/mono/character" className="nav">收藏的角色</a></li>
                    <li><a href="/user/ychz/mono/person" className="nav">收藏的人物</a></li>				 */}
                  </ul>
                </li>
                <li><a href="//bangumi.tv/rakuen" className="top_lite"><span>超展开</span></a></li>
                <li><a href="//bangumi.tv/group" className="top"><span>小组</span></a>
                  <div className="clear" />
                  <ul className="clearit">
                    <li><a href="//bangumi.tv/group/discover" className="nav">随便看看</a></li>
                    <li><a href="//bangumi.tv/group/all" className="nav">所有小组</a></li>
                    {/* <li><div className="sep">我</div></li>
                    <li><a href="/group/my_topic" className="nav">发表的话题</a></li>
                    <li><a href="/group/my_reply" className="nav">回复的话题</a></li>
                    <li><a href="/group/mine" className="nav">参加的小组</a></li> */}
                  </ul>
                </li>
                <li><a href="#" className="top"><span>探索</span></a>
                  <div className="clear" />
                  <ul className="explore clearit">
                    <li className="single"><a href="//bangumi.tv/timeline">时空管理局</a></li>		
                    <li className="group">
                      <span><a href="//bangumi.tv//blog">日志</a><a href="//bangumi.tv/index">目录</a><a href="//bangumi.tv/wiki">维基人</a></span>
                    </li>				
                    <li className="group"><span><a href="//bangumi.tv/magi">MAGI 问答</a><a href="//bangumi.tv/dollars">Dollars</a></span></li>				
                    <li className="group">
                      <span className="root">客户端</span>
                      <span><a href="//bangumi.tv/onair">onAir</a><a href="//bangumi.tv/group/topic/5155">Mobile</a></span>
                    </li>				
                  </ul>
                </li>
              </ul>	
            </div>
          </div>
          <div id="headerSearchWrapper">
            <div id="headerSearch">
              <form action="//bangumi.tv/subject_search" method="post">
                <div className="inner">
                  <select name="cat" id="siteSearchSelect">
                    <option value="all">全部</option>
                    <option value={2} defaultValue="selected">动画</option>
                    <option value={1}>书籍</option>
                    <option value={4}>游戏</option>
                    <option value={3}>音乐</option>
                    <option value={6}>三次元</option>
                    <option value="person">人物</option>			
                  </select>
                  <input id="search_text" name="search_text" className="textfield" type="text" />
                  <input type="submit" name="submit" defaultValue="搜索" className="search" />
                </div>
              </form>
            </div>   
          </div>  
        </div>
      </div>
    );
}

export default Header