import React from 'react';
import Header from "./header";
import Footer from "./footer";
import Dock from "./dock";
import {getCatId, getCatName} from './utils.js'
import SearchBar from "./SearchBar";

class TagCat extends React.Component{
  constructor(props){
      super(props);
        this.state = {
            results:[],
            total_results: 0,
            total_pages: 0,
            page_number: 1,
            has_next_page: false,
        }
        this.cat = props.match.params.cat
        this.catId = getCatId(this.cat)
        this.catName = getCatName(this.cat)
        // this.fetchTask = this.fetchTask.bind(this)
        this.query = props.location.search
        //console.log(`props.location.search: ${props.location.search}`)
        const query_string = new URLSearchParams(this.query)
        //console.log(`query_string: ${query_string}`)
        this.page = query_string.get('page') == null ? 1 : query_string.get('page')
        //default page to 1
        //console.log(`page: ${this.page}`)
       
  }

  componentDidMount(){
    this.fetchTask()
    document.title = `多标签搜索(分类标签): ${getCatName(this.cat)}`
  }
  

  fetchTask(){
    //console.log('Fetching...')
    //console.log(this.cat)
    //console.log(this.catId)
    let apiUrl = `https://${window.location.host}/api/tags/?size=100&cat=${this.catId}&page=${this.page}`
    fetch(apiUrl)
    .then(response => response.json())
    .then(data => {
        this.setState({
          results: data['results'],
          total_results: data['total_results'],
          total_pages: data['total_pages'],
          page_number: data['page_number'],
          has_next_page: data['has_next_page'],
        })
    })
  }

  render(){
    var results = this.state.results
    var total_pages = this.state.total_pages
    var page_number = this.state.page_number
    var next_page_number = page_number === total_pages ? 1 : parseInt(page_number) + 1 
    let cat = this.cat
    let catName = this.catName
    if (results.length == 0) 
      return null
    //console.log('should only be called once')
    //console.log(results)
    return (
        <div>
          {/* meta */}
        <div id="wrapperNeue" className="wrapperNeue">
          <Header data={cat}/>
          <div id="main" className="png_bg"><div id="header">
              <h1>{"Bangumi "+catName+"标签"}</h1>
            </div>
            <div className="columns clearit">
              <div id="columnA" className="column">
                <div id="tagList">

                
                {
                  results.map(function(tag, index){
                    return(
                      <>
                        <a href={"/"+cat +"/tags/?q="+tag.name} className="l level1">{tag.name}</a><small className="grey">({tag.count})</small> &nbsp;
                      </>
                    )
                  })
                }

                </div>
                <hr className="board" />
                <div id="multipage"><div className="page_inner">
                    {   
                        [...Array(Math.min(10, total_pages)+1).keys()].slice(1).map(function(index){
                            return (
                                (
                                    index == page_number ?
                                    <strong className="p_cur">{index}</strong> :
                                    <a href={"?page="+index} className="p">{index}</a>
                                )
                            )
                        })
                    }
                <a href={"?page="+next_page_number} className="p">››</a>
                <a href={"?page="+total_pages} className="p">›|</a>
                <a className="p_pages" style={{padding: '0px'}}>
                    <input className="inputtext" style={{width: '30px'}} type="text" name="custompage" onkeydown="if(event.keyCode==13) {window.location='?page='+this.value + ''; return false;}" />
                </a>
                <span className="p_edge">(&nbsp;{page_number}&nbsp;/&nbsp;{total_pages}&nbsp;)</span>
                </div></div>
              </div>
              <div id="columnB" className="column"><div className="SidePanel png_bg" align="left">
                  <h2>{catName+"标签搜索"}</h2>
                  <SearchBar cat={cat}/>
                </div>
                {/* <div className="SidePanelLow png_bg">
                  <h2>分类浏览</h2>
                  <div className="innerWrapper">
                    <ul className="cata4c clearit">
                      <li><a href="/anime/browser" className="l">全部</a></li>    
                      <li><a href="/anime/browser/tv" className="l ">TV</a></li>
                      <li><a href="/anime/browser/web" className="l ">WEB</a></li>
                      <li><a href="/anime/browser/ova" className="l ">OVA</a></li>
                      <li><a href="/anime/browser/movie" className="l ">剧场版</a></li>
                      <li><a href="/anime/browser/misc" className="l ">其他</a></li>
                    </ul>
                  </div>
                </div> */}
                {/* <div className="SidePanelLow png_bg">
                  <h2>时间浏览</h2>
                  <div className="innerWrapper">
                    <ul id="airTimeMenu" className="cata4c clearit">
                      <li id="futureAirTime"><a href="#" className="l">来年们</a></li>
                      <li className="airYear"><a href={"/"+cat+"/tags/?q=2022"} className="l">2022年</a></li>
                      <li className="airYear"><a href={"/"+cat+"/tags/?q=2021"} className="l">2021年</a></li>
                      <li className="airYear"><a href={"/"+cat+"/tags/?q=2020"} className="l">2020年</a></li>
                      <li className="airYear"><a href={"/"+cat+"/tags/?q=2019"} className="l">2019年</a></li>
                      <li className="airYear"><a href={"/"+cat+"/tags/?q=2018"} className="l">2018年</a></li>
                      <li className="airYear"><a href={"/"+cat+"/tags/?q=2017"} className="l">2017年</a></li>
                      <li className="airYear"><a href={"/"+cat+"/tags/?q=2016"} className="l">2016年</a></li>
                      <li className="airYear"><a href={"/"+cat+"/tags/?q=2015"} className="l">2015年</a></li>
                      <li className="airYear"><a href={"/"+cat+"/tags/?q=2014"} className="l">2014年</a></li>
                      <li className="airYear"><a href={"/"+cat+"/tags/?q=2013"} className="l">2013年</a></li>
                      <li id="pastAirTime"><a href="#" className="l">往年们</a></li>
                    </ul>
                  </div>
                </div> */}
                <div className="menu_inner" align="left">
                  {/* <a href="//bangumi.tv/tag" className="l">浏览热门标签</a><br /> */}
                  <a href={"//bangumi.tv/"+cat} className="l">{"返回"+catName+"频道"}</a>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </div>
        <div className="homeBg" />
        <Dock />

      </div>
    );
  }
}

export default TagCat;
