import React from "react";
import DarkMode from "./DarkMode";

function Dock(){
    return(
        <div id="dock">
        <div className="content">
        <ul className="clearit">
            <li><DarkMode/></li>
            <li><a href="https://bangumi.tv/notify/all">提醒</a> | <a href="https://bangumi.tv/pm">短信</a> | <a href="https://bangumi.tv/settings">设置</a></li>
        </ul>
        </div>
        </div>
    )
}

export default Dock