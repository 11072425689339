function NavBar(){
    return <nav id='nav'>
        <a href='/' className='site-title'>City Hunter</a>
        <ul>
            <li>
                <a href='/help' className='site-link'>Help</a>
            </li>
            <li>
                <a href='/about' className='site-link'>About</a>
            </li>
        </ul>
    </nav>
}

export default NavBar
