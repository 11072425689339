import React from 'react'
import SearchCategory from './SearchCategory'
import SearchBar from "./SearchBar";
import NavBar from "./Navbar";
import Dock from "./dock";


class SearchHome extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            //this guarantee if user not select a category in SearchCategory
            //it will pick search anime by default
            selectValue: 'anime'
        }
    }

    handleSelectValue = state => {
        this.setState(state);
    }

    render() {
        return (
            <div id="wrapperNeue" className="wrapperNeue">
            <NavBar/>
                <div id="main" className="png_bg">
                <SearchCategory liftState={this.handleSelectValue}/>
                <SearchBar cat={this.state.selectValue}/>
                </div>
                <div className="f"></div>
                <Dock/>
            </div>
        );
    }
}

export default SearchHome