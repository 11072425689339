import React from "react";

function getStarLevelSpan(star_level){
    return (
        <span className="starstop-s">
              <span className={"starlight stars"+star_level}></span>
        </span>
    )
}


export function getRateInfo(subject){
    let has_rating = subject.rating != null ? true: false
    let rating_round = (Math.round(subject.rating*10)/10).toFixed(1)
    let star_level = Math.round(subject.rating)
    let lt_10 = subject.votes < 10 ? true : false
    // console.log(`name: ${subject.name_cn}, has_rating: ${has_rating}, start level: ${star_level}, rating_round: ${rating_round}`)
    return (<p className="rateInfo">
             {!lt_10 && getStarLevelSpan(star_level)}
             {!lt_10 && <small className="fade"> {rating_round} </small>}
             {lt_10 ? <span className="tip_j">(少于10人评分)</span> :
                <span className="tip_j">{'('+subject.votes+'人评分)'}</span>}
         </p>
    )
}