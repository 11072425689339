import React from "react";

class SearchCategory extends React.Component{
    constructor(props) {
        super(props);
        this.state = { cat: 'anime'}
        this.handleClick = this.handleClick.bind(this)
    }

    handleClick(e){
        let updatedCat = e.target.getAttribute('value')
        if (this.state.cat === updatedCat){
            //if cat is same don't liftState, therefore searchBar won't rerender
            // console.log('category did not change')
            //prevent changing focus from searchbar when clicking same cat
            e.stopPropagation()
            return
        }
        this.props.liftState({selectValue: updatedCat})
        this.setState({cat: updatedCat})
    }


    render() {
        let cat = this.state.cat
        return (
        <div>
            <ul id="navMenuNeue" className="clearit">
            <li><a href='javascript:void(0);' value="anime" className={(cat === 'anime'? 'focus' : 'top') + " chl anime"} onClick={this.handleClick}><span value="anime">动画</span></a>
            </li>
            <li><a href='javascript:void(0);' value="book" className={(cat === 'book'? 'focus' : 'top') + " chl"} onClick={this.handleClick}><span value="book">书籍</span></a>
            </li>
            <li><a href='javascript:void(0);' value="music" className={(cat === 'music'? 'focus' : 'top') + " chl"} onClick={this.handleClick}><span value="music">音乐</span></a>
            </li>
            <li><a href='javascript:void(0);' value="game" className={(cat === 'game'? 'focus' : 'top') + " chl"} onClick={this.handleClick}><span value="game">游戏</span></a>
            </li>
            <li><a href='javascript:void(0);' value="real" className={(cat === 'real'? 'focus' : 'top') + " chl real"} onClick={this.handleClick}><span value="real">三次元</span></a>
            </li>
            </ul>
        </div>
    )
    }

}

export default SearchCategory

