import React from 'react';
import Header from "./header";
import Footer from "./footer";
import Dock from "./dock";
import {getCatId, getCatName} from './utils.js'
import SearchBar from "./SearchBar";
import {getRateInfo} from "./BrowserItemList";

class TagSearch extends React.Component{
    constructor(props){
        super(props);
          this.state = {
              subjects: [],
              tag_cloud: [],
              total_results: 0,
              total_pages: 0,
              page_number: 1,
              has_next_page: false,
          }
        //console.log(`props.location.search: ${props.location.search}`)
        this.cat = props.match.params.cat
        this.catId = getCatId(this.cat)
        this.catName = getCatName(this.cat)
        this.query = props.location.search
          // this.fetchTask = this.fetchTask.bind(this)
        const query_string = new URLSearchParams(this.query)
        //console.log(`query_string: ${query_string}`)
        this.tags = query_string.get('q')
        this.page = query_string.get('page') == null ? 1 : query_string.get('page')
        this.order = query_string.get('order') == null ? '-votes' : query_string.get('order')
        this.year = query_string.get('year') == null ? '' : `&year=${query_string.get('year')}`
        this.tagButtonTexts = decodeURIComponent(this.tags).split(' ')
        //console.log(`tags: ${this.tags}`)
        //default page to 1
        //console.log(`page: ${this.page}`)
        this.removeTagAndRefresh = this.removeTagAndRefresh.bind(this)
    }
  
    componentDidMount(){
      this.fetchTask()
      document.title = `多标签搜索(${getCatName(this.cat)}): ${this.tags}`
    }

      componentDidUpdate(prevProps) {
        //console.log(`componentDidUpdate`)
        //console.log(`this.props.location: ${JSON.stringify(this.props.location)}`)
        //console.log(`prevProps.location: ${JSON.stringify(prevProps.location)}`)
    }


    fetchTask(){
      //console.log('Fetching...')
      //console.log(this.catId)
      let apiUrl = `https://${window.location.host}/api/subjects/?size=20&cat=${this.catId}&tags=${this.tags}&page=${this.page}&order=${this.order}${this.year}`
      //console.log(`apiUrl: ${apiUrl}`)
      fetch(apiUrl)
      .then(response => response.json())
      .then(data => {
          this.setState({
            subjects: data['results']['subjects'],
            tag_cloud: data['results']['tag_cloud'],
            total_results: data['total_results'],
            total_pages: data['total_pages'],
            page_number: data['page_number'],
            has_next_page: data['has_next_page'],
          })
          //console.log(this.state.subjects)
          //console.log(this.state.tag_cloud)
      })
    }

    getRemovableTagButtons(){
        //console.log("rendering removableTagButtons")
        var tagButtons = []
        for(var i = 0; i< this.tagButtonTexts.length; i++){
            tagButtons.push(<a className='removable' onClick={this.removeTagAndRefresh}>{this.tagButtonTexts[i]}</a>)
        }
        //div here is necessary
        return (<div>{tagButtons}</div>)
    }


    getBrowserItemList(subjects){
        return (
            <ul id="browserItemList" className="browserFull">
          {
              subjects.map(function(subject, index){
                  let subject_id = subject.id
                  let has_rank = subject.ranking != null
                  let has_rating = subject.rating != null
                  let even_odd = index % 2 == 0 ? 'even' : 'odd'
                  let has_name_cn = subject.name_cn === "" ? false : true
                  let has_img_url = subject.img_url === "" ? false : true
//                               https://stackoverflow.com/questions/7556591/is-the-javascript-date-object-always-one-day-off
                  let displayDate = subject.release_date == null ? null : new Date(subject.release_date.split('-'));
                  return(

                    <li id={'item_'+subject_id} className={"item "+ even_odd+ " clearit"}>
                    <a href={'//bangumi.tv/subject/'+subject_id} className="subjectCover cover ll">
                    <span className="image">
                      <img
                          src={has_img_url ? subject.img_url : '//bangumi.tv/img/no_img.gif'}
                          className="cover"/>
                    </span>
                    <span className="overlay"></span>

                    </a>
                    <div className="inner">
                    {
                        has_name_cn ?
                        (<h3>
                            <a href={'//bangumi.tv/subject/'+subject_id} className="l">{subject.name_cn}</a>
                            <small className="grey">{subject.name}</small>
                        </h3>)
                        :
                        (<h3>
                            <a href={'//bangumi.tv/subject/'+subject_id} className="l">{subject.name}</a>
                        </h3>)
                    }
                    {
                    has_rank ?
                      (<span className="rank"><small>Rank </small>{subject.ranking}</span>)
                      : <></>
                    }
                      <p className="info tip">
                        { displayDate == null ? "": displayDate.toLocaleDateString("zh-CN")}
                      </p>
                        {has_rating && getRateInfo(subject)}
                    </div>
                  </li>
                  )
              })
          }
        </ul>)
    }

    removeTagAndRefresh = (event) => {
        //console.log(event)
        // event.target.value is undefined use innerHTML
        //console.log(`tagButtonTexts: ${this.tagButtonTexts}`)
        let newTagButtonTexts = this.tagButtonTexts.filter(item => item !== event.target.innerHTML)
        let tagsAfterRemoval = newTagButtonTexts.join(' ')
        //console.log(tagsAfterRemoval)
        //console.log(`this.props.history: ${this.props.history}`)
        var path = '/' + this.cat + '/tags/?q=' + tagsAfterRemoval + '&order=' + this.order
        if (newTagButtonTexts.length === 0)
            path = '/' + this.cat + '/tag'
        this.props.history.push(path)
    }


    render(){
        var subjects = this.state.subjects
        var tag_cloud = this.state.tag_cloud
        var total_pages = this.state.total_pages
        var page_number = this.state.page_number
        var next_page_number = page_number === total_pages ? 1 : parseInt(page_number) + 1 
        let tags = this.tags
        let cat = this.cat
        let catName = this.catName
        let order = this.order
        let currYear = parseInt(new Date().getFullYear())
        let year = this.year
        if (subjects.length == 0) {
            //console.log('retrieved subjects length is 0')
            return (
            
            <div id="wrapperNeue" className="wrapperNeue">
                <Header data={cat}/>
            </div>
    
            )
        }
        //console.log('should only be called once')

        return (
            <>
            <div id="wrapperNeue" className="wrapperNeue">
            <Header data={cat}/>
            <div id="main" class="png_bg">
            <div id="header">
            <h1><div className='currentSelectTags'>
                <div>{catName+"标签:"}</div>
                {this.getRemovableTagButtons(this.tags)}
            </div></h1>
            </div>
            <div className="columns clearit">

                <div id="columnSubjectBrowserA" className="column">
                  <div id="browserTools" className="clearit">
                    <a href={"/"+cat+"/tags/?q="+tags+"&order=ranking"+year}
                       className={"l " + (order==="ranking" ? "focus" : "")}>按排名排序</a>
                        ·
                    <a href={"/"+cat+"/tags/?q="+tags+"&order=-release_date"+year}
                      className={"l " + (order==="-release_date" ? "focus" : "")}>按日期排序</a>
                         · 
                    <a href={"/"+cat+"/tags/?q="+tags+"&order=-rating"+year}
                      className={"l " + (order==="-rating" ? "focus" : "")}>按评分排序</a>
                       · 
                    <a href={"/"+cat+"/tags/?q="+tags+"&order=-votes"+year}
                      className={"l " + (order==="-votes" ? "focus" : "")}>按评价数排序</a>                      
                    <ul id="browserTypeSelector" className="browserTypeSelector">
                      <li><a href="#"
                             id="list_selector"><span>精简</span></a></li>
                      <li><a href="#" id="full_selector"
                             className="active"><span>列表</span></a></li>
                      <li><a href="#"
                             id="grid_selector"><span>网格</span></a></li>
                    </ul>
                    </div>
                    <div className="section">
                        {this.getBrowserItemList(subjects)}
                    </div>
                    <div id="multipage"><div className="page_inner">
                    {   
                        [...Array(Math.min(10, total_pages)+1).keys()].slice(1).map(function(index){
                            return (
                                (
                                    index == page_number ?
                                    <strong className="p_cur">{index}</strong> :
                                    <a href={"?q="+tags+"&order="+order+year+"&page="+index} className="p">{index}</a>
                                )
                            )
                        })
                    }
                    {/* todo should be disable when reach end */}
                    <a href={"?q="+tags+"&order="+order+year+"&page="+next_page_number} className="p">››</a>
                    {/* todo should be disable when reach end*/}
                    <a href={"?q="+tags+"&order="+order+year+"&page="+total_pages} className="p">›|</a>
                    <a className="p_pages" style={{padding: '0px'}}>
                        <input className="inputtext" style={{width: '30px'}} type="text" name="custompage" onkeydown="if(event.keyCode==13) {window.location='?page='+this.value + ''; return false;}" />
                    </a>
                    <span className="p_edge">(&nbsp;{page_number}&nbsp;/&nbsp;{total_pages}&nbsp;)</span>
                    </div></div>
                  </div>
                  <hr className="board" />
                      
            
                  <div id="columnSubjectBrowserB" class="column">	
    <div class="sideInner">
        <h2 class="subtitle">{catName+"标签搜索"}</h2>
        <SearchBar cat={cat}/>
        <h2 class="subtitle">标签类别</h2>
        <ul class="grouped clearit">
            <li>
              {<a href={"/anime/tags/?q="+tags+"&order="+order} class={"l " + (cat === "anime" ? "focus": "")}><span>动画</span></a> }
            </li>
            <li>
              {<a href={"/book/tags/?q="+tags+"&order="+order} class={"l " + (cat === "book" ? "focus": "")}><span>书籍</span></a>}
            </li>
            <li>
              {<a href={"/music/tags/?q="+tags+"&order="+order} class={"l " + (cat === "music" ? "focus": "")}><span>音乐</span></a>}
            </li>
            <li>
              {<a href={"/game/tags/?q="+tags+"&order="+order} class={"l " + (cat === "game" ? "focus": "")}><span>游戏</span></a>}
            </li>
            <li>
              {<a href={"/real/tags/?q="+tags+"&order="+order} class={"l " + (cat === "real" ? "focus": "")}><span>三次元</span></a>}
            </li>
        </ul>
    
        <h2 class="subtitle">时间</h2>
<ul id="airTimeMenu" class="grouped clearit">
    <li id="futureAirTime"><a href="javascript:void(0)" class="l">来年们</a></li>
    {
      [...Array(10).keys()].map(x=>(<li class="airYear"><a href={"/"+cat+"/tags/?q="+tags+"&order="+order+"&year="+(currYear-x)} class="l">{(currYear-x)+"年"}</a></li>))
    }
    <li id="pastAirTime"><a href="javascript:void(0)" class="l">往年们</a></li>
</ul>                  
          
<h2 class="subtitle">当前热门</h2>
<ul class="grouped clearit">
{
      tag_cloud.map(function(tag, index){
        return(
          <li>
            <a href={"/"+cat +"/tags/?q="+tags+" "+tag[0]+"&order="+order} className="l">{tag[0]}<small className="grey">({tag[1]})</small> &nbsp;</a>
          </li>
        )
      })
    }
</ul>
        <h2 class="subtitle">更多</h2> 
        <ul class="grouped clearit">
        <li><a href={"/"+cat+"/tag"} class="l">{"浏览全部"+catName+"标签"}</a></li>
        <li><a href={"//bangumi.tv/"+cat+"/browser"} class="l">{"浏览全部"+catName}</a></li>        
        </ul>
    </div>   	

                    </div>
            </div>
            <Footer />
            </div>
            </div>
            <div class="f"></div>
            <Dock/>
            </>
        )
    }
}

export default TagSearch
