import React from 'react'
import NavBar from "./Navbar";
import Dock from "./dock";


class Help extends React.Component{

    render() {
        return (
            <div id="wrapperNeue" className="wrapperNeue">
            <NavBar/>
                <div id="main" className="png_bg">
                    <div className="columns clearit">
                        <div className="column">
                            <br/>
                            <div>
                                <h2>搜索</h2>
                            </div>
                            <br/>
                            <ul>
                                <li>1. 输入你想搜索的标签名称（部分即可）</li>
                                <br/>
                                <li>2. 用 <kbd>Tab</kbd> 和 <kbd>Enter</kbd> 自动补全</li>
                                <br/>
                                <li>3. 重复 1，2 输入多标签</li>
                                <br/>
                                <li>4. 最后再次 <kbd>Enter</kbd> 进行搜索</li>
                                <br/>
                                <li>你还可以搜索其他类别（书籍，游戏，音乐，三次元）</li>
                            </ul>
                            <div>
                            <br/>
                            <br/>
                            <h2>标记</h2>
                            </div>
                            <br/>
                            <ul>
                                <li>特征条目<sup>0</sup>在标记完成后可以很快的查找到，该条目的数据将在1min<sup>1</sup>后自动更新</li>
                                <br/>
                                <div>
                                <h3>怎样标记才能被检索到</h3>
                                    <br/>
                                    <ul>
                                        <li>以搜动画为例，当你正常<sup>2</sup>标记条目为以下任意状态后（在看，已看，看过，搁置，抛弃）</li>
                                        <br/>
                                        <li>如果你在标记过程中添加了标签<sup>3</sup>，下次用新标签搜索时，该条目会出现在搜索结果中。</li>
                                        <br/>
                                        <li>如果你在添加/修改了播出时间，然后进行了标记，下次则可按该年份搜索到该条目。</li>
                                    </ul>
                                </div>
                                <br/>
                                <div>
                                <h3>哪些数据会更新</h3>
                                    <br/>
                                    <div>标签，日期，封面，条目名称，条目中文名称，评分，排名</div>
                                </div>
                                <br/>
                            </ul>
                            <div>
                                <h2>说明</h2>
                                <br/>
                                <ul>
                                    <li><sup>0</sup>目前特征条目的范围基本为 未登录不可见条目/注册小于60天不可见条目</li>
                                    <br/>
                                    <li><sup>1</sup>平均时间是1min，实际延迟也取决于bgm时间线的刷新快慢，一般来说在几秒到两分钟之间</li>
                                    <br/>
                                    <li><sup>2</sup>在标记时没有勾选"仅自己可见"</li>
                                    <br/>
                                    <li><sup>3</sup>添加的标签是该条目下所有人可见的标签即可</li>
                                    <br/>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="f"></div>
                <Dock/>
            </div>
        );
    }
}

export default Help