import React, {useEffect} from 'react';
import './App.css';
import TagCat from './TagCat';
import {Switch, Route} from 'react-router-dom';
import TagSearch from './TagSearch';
import SearchHome from './SearchHome'
import Help from "./Help";
import About from "./About";
import icon from './img/favicon.ico';


function App(){
    useEffect(() => {
    const favicon = document.getElementById('favicon');
    favicon.setAttribute('href', icon);
}, []);
    return (
      <Switch>
        <Route path="/:cat/tag/" component={TagCat} />
        <Route path="/:cat/tags/" component={(props) => <TagSearch {...props} key={window.location.href}/>} />
        <Route path="/help" component={Help} />
        <Route path="/about" component={About} />
        <Route path="/" component={SearchHome} />
      </Switch>
    )
}

export default App;
