import React from 'react';
import AsyncCreatableSelect from 'react-select/async-creatable';
import debounce from "debounce-promise";
import {withRouter} from "react-router-dom";
import {getCatId} from "./utils";

class SearchBar extends React.Component {

  state =  { inputValue: '' , selectOptions: null};
    stateRef = React.createRef()
    asyncSelectRef = React.createRef()

    componentWillReceiveProps(nextProps,context) {
        if (nextProps.cat !== this.props.cat) {
            //console.log(`nextProps.cat: ${nextProps.cat} vs this.props.cat: ${this.props.cat}`)
        }
        this.asyncSelectRef.current.focus()
        // this.asyncSelectRef.current.loadOptions = this.debouncedLoadOptions
    }

   fetchOptions = (inputValue, cat) => {
      if (!inputValue){
          // if no input at all, current no suggestion
          return null
      }
       let catId = getCatId(cat)
       //console.log(`Fetching ${cat} tags based on prefix`)
      let apiUrl = `https://${window.location.host}/api/tags/?size=20&cat=${catId}&prefix=${inputValue}`
      //console.log(`apiUrl: ${apiUrl}`)
      var res = [];
      return fetch(apiUrl)
          .then(response => response.json())
          .then(data => {
          var suggestion = data['results'];
          //console.log(suggestion);
          //console.log(suggestion.length);
          for(var i = 0; i < suggestion.length; i++){
              var dict = {};
              dict['value'] = suggestion[i]['name'];
              dict['label'] = suggestion[i]['name'];
              dict['count'] = suggestion[i]['count'];
              res.push(dict);
          }
          //console.log(res);
          return res;
      })
};

 promiseOptions = (inputValue, cat) =>
  new Promise((resolve) => {
      resolve(this.fetchOptions(inputValue, cat));
  });

 debouncedLoadOptions = debounce((searchTerm, callback) => {
    return this.promiseOptions(searchTerm, this.stateRef.current)
}, 250, {trailing: true})

  handleChange = (e) => {
      var tags = []
      for(var i = 0; i < e.length; i++){
          //console.log(e[i]['value'])
          tags.push(e[i]['value'])
      }
      let multiTags = tags.join(' ')
      //console.log(multiTags)
      this.setState({selectOptions: multiTags})
      //https://stackoverflow.com/questions/30782948/why-does-calling-react-setstate-method-not-mutate-the-state-immediately
      //console.log(this.state.selectOptions)
  }
  handleSubmit = (e) => {
      //console.log(this.state.selectOptions)
      this.props.history.push('/'+this.stateRef.current+'/tags/?q='+this.state.selectOptions)
  }
  render() {
    //console.log(`this.props.cat: ${this.props.cat}`)
      this.stateRef.current = this.props.cat
      //console.log(`this.stateRef.current :${this.stateRef.current}`)
    return (
    <div>
    {/*<div hidden={true}>{this.props.cat}</div>*/}
    <form id="tagSearch" name="tagSearch" onSubmit={this.handleSubmit} method="GET">
      <AsyncCreatableSelect
        className='my-react-select-container'
        classNamePrefix="my-react-select"
        placeholder="输入标签，自动补全，可多标签"
        autoFocus
        isMulti
        //have multi search category, cache options will lead to wrong suggestions
        cacheOptions={false}
        defaultOptions
        loadOptions={this.debouncedLoadOptions}
        onChange={this.handleChange}
        ref={this.asyncSelectRef}
      />
        <input type="submit" value="搜索" className="btnPink"/>
    </form>
    </div>
    );
  }
}
export default withRouter(SearchBar)