import React from 'react'
import NavBar from "./Navbar";
import Dock from "./dock";


class About extends React.Component{

    render() {
        return (
            <div id="wrapperNeue" className="wrapperNeue">
            <NavBar/>
                <div id="main" className="png_bg">
                    <div className="columns clearit">
                        <div className="column">
                            <br/>
                            <div>
                            <h2>关于</h2>
                            </div>
                            <br/>
                            <ul>
                                <li>cityhunter.me is meant to help you hunt ACGN content that you are passionate about.</li>
                                <br/>
                                <li>上面这句话用中文说太尬了，英语的话打出来不脸红</li>
                            </ul>
                            <br/>
                            <div>
                            <h2>初衷</h2>
                            </div>
                            <br/>
                            <ul>
                                <li>今年三月前后，现行的多标签搜索特征条目突然搜不出来了，加之一些标签因为标记占比相对较低，也会检索不出来</li>
                                <br/>
                                <li>所以也主要是为满足自己的需求，最初基于这两点实现了这个多标签搜索</li>
                                <br/>
                                <li>自己做了之后才发现，更新数据存档这种事月更都是不可能的，所以就想着能自动更新数据就好了，于是就有了目前这个版本</li>
                            </ul>
                            <br/>
                            <div>
                            <h2>反馈</h2>
                            </div>
                            <br/>
                            <ul>
                                <li>搜索速度目前不是很快，还有很多功能需要完善，甚至有的可能有bug，各位站内私我就好</li>
                                <br/>
                                <li>或者 Email <a class='l' href='mailto:cityhunterdotme@gmail.com'>cityhunterdotme@gmail.com</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="f"></div>
                <Dock/>
            </div>
        );
    }
}

export default About